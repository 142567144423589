import requset from '@/util/requset'

export function Controller(data) {
    return requset({
        url: '/ruoyipc/curriculumController/getByid',
        method: 'POST',
        headers: {
            "Content-Type":"application/json"
          },
        data
    })
}
export function Classification(data) {
    return requset({
        url: '/ruoyipc/courseClassification/list',
        method: 'get',
        data
    })
}
export function Activit(data) {
    return requset({
        url: '/home/iPlatformActivities',
        method: 'get',
        data
    })
}
export function iPlatformt(data) {
    return requset({
        url: '/home/iPlatformStatement',
        method: 'POST',
        data
    })
}
export function iCar(data) {
    return requset({
        url: '/home/iCarouselChart',
        method: 'get',
        data
    })
}
export function iIndustry(data) {
    return requset({
        url: '/home/iIndustry',
        method: 'get',
        data
    })
}
export function iPolicies(data) {
    return requset({
        url: '/home/iPolicies',
        method: 'get',
        data
    })
}