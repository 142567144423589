<template>
    <div>


        <div>
            <el-popover placement="top-start" width="310" trigger="hover">
                <div>
                    <div class="user">
                        <div class="users">
                            <div class="portrait"><img :src="user.photoAddress" alt="" class="portrait"></div>
                            <div class="userx">
                                <div>{{ user.fullName }}</div>
                                <div>{{ user.userName }}</div>
                            </div>
                        </div>
                        <div class="zcBut">
                            <div>注册成员</div>
                        </div>
                    </div>
                    <div class="regInf">
                        <div class="info" v-for="(item, index) in regInfList" :key="index">
                            <div style="color: #A0A0A0;">{{ item.title }}</div>
                            <div id="copyID" style="color:#444444; margin-left: 5px; ">{{ item.info }}</div>
                            <but style="cursor: pointer;" @click="copy(item.info)" class="copy"
                                v-show="item.copy == '' ? false : true"> 
                                {{ item.copy }}
                            </but>
                        </div>
                    </div>
                    <div>
                        <div class="information">
                            <div class="kuang" @click="gotoinfo">消息中心</div>
                            <div class="kuang" @click="open">账号注销</div>
                        </div>
                        <div class="information" v-show="userShow">
                            <div class="kuang" @click="gotoinvo">我要发票</div>
                            <div class="kuang" @click="gotoCert">证书查看</div>
                        </div>
                        <div class="information">
                            <div class="kuang" @click="gotoSeting" v-show="userShow">资料修改</div>
                            <div class="kuang" @click="gotostaff" v-show="userShows">员工管理</div>
                        </div>
                    </div>
                    <div style="margin-top: 20px;" v-show="userShowt">

                        <div class="grewm">
                            <canvas id="canvas" class="qrcode_user"></canvas>
                        </div>
                        <div class="cxewm">诚信二维码</div>
                    </div>
                </div>
                <div style="display: flex;" slot="reference">
                    <el-col :span="12">
                        <div class="demo-basic--circle">
                            <div class="block">
                                <el-avatar size="small" :src="user.photoAddress"></el-avatar>
                            </div>
                        </div>
                    </el-col>
                    <div style="line-height: 28px; margin-left: 10px;">{{ user.phone }}</div>
                </div>
            </el-popover>
        </div>


    </div>
</template>
    
<script>
import { getToken, getUserfo, getcodefo } from "@/util/auth";
import { cancellation } from "@/api/login";
import QRCode from "qrcode"
export default {
    name: "user",
    components: {

    },
    // props:{
    //     getInfo:{
    //         typeof:Object
    //     }
    // },
    computed: {
        user() {
            return JSON.parse(getUserfo())

        }
    },
    created() {
        var user = JSON.parse(getUserfo())
        this.userId = user.id
        this.qrcode = getcodefo()
        if (user.userType == 0) {
            this.userShow = true
            this.zsSHow=true
            this.userShows = false
            this.userShowt=true
        } else if(user.userType == 1){
            this.zsSHow=false
            this.userShows = true
            this.userShowt=false
        } else {
            this.userShow = true
            this.userShows = false
            this.zsSHow=false
            this.userShowt=false
        }
        this.regInfList[0].info = user.obtainingTime

        this.regInfList[2].info = user.certificateId
        if (user.userType == 1 ) {
            this.regInfList[1].info = ''
            this.regInfList[1].title = ''
        } else {
            this.regInfList[1].info = user.obtainlastTime
        }
        var a = []
        if (user.userType == 1 ||user.userType == 2 ) {
            a = this.regInfList.slice(0, 1)
        } else {
            a = this.regInfList
        }
        this.regInfList = a

    },
    data() {
        return {
            zsSHow:false,
            userShows: false,
            userShow: false,
            userId: '',
            circleUrl: "https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png",
            squareUrl: "https://cube.elemecdn.com/9/c2/f0ee8a3c7c9638a54940382568c9dpng.png",
            lonShow: false,
            regInfList: [
                { title: '注册时间：', info: '2022.11.01', copy: '' },
                { title: '有效期至：', info: '2023.11.01', copy: '' },
                { title: '证书ID：', info: 'SC202200000', copy: '复制' },
            ],
            list: [
                { name: "首页", path: '/' },
                { name: "平台介绍", path: '/about' },
                { name: "政策法规", path: '/policy' },
                { name: "行业资讯", path: '/info' },
                { name: "我要注销", path: '/enroll' },
                { name: "信用档案", path: '/archives' },
                { name: "在线学习", path: '/study' },
            ],
            qrcode: ''
        };
    },
    methods: {
        delCookie() {
      var cookies = document.cookie.split(";");
      for (var i = 0; i < cookies.length; i++) {
        var cookie = cookies[i];
        var eqPos = cookie.indexOf("=");
        var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
        document.cookie =
          name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/";
      }
      if (cookies.length > 0) {
        for (var i = 0; i < cookies.length; i++) {
          var cookie = cookies[i];
          var eqPos = cookie.indexOf("=");
          var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
          var domain = location.host.substr(location.host.indexOf("."));
          document.cookie =
            name +
            "=;expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/; domain=" +
            domain;
        }
      }
      this.$router.go(0)
      location.reload()
      this.$message({
        message: '注销成功并退出登录！',
        type: 'warning'
      });
      this.$router.push({
        name: 'home',
      })
    },
        open() {
        this.$confirm('此操作将注销该账号, 是否继续?', '温馨提示！！', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.cancellation()
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '取消注销'
          });          
        });
      },
        cancellation() {
            cancellation(
                {
                    id: this.userId,
                    reason: '不再使用'
                }
            ).then((res) => {
                if(res.message=="注销成功"){
                    this.delCookie()
                }
            })
        },
        //点击某个摁扭执行事件 
        copy(data) {
            let url = data;
            //新建一个文本框
            let oInput = document.createElement('input');
            //赋值给文本框
            oInput.value = url;
            document.body.appendChild(oInput);
            // 选择对象;
            oInput.select();
            // 执行浏览器复制命令
            document.execCommand("Copy");
            //复制完成删除掉输入框
            oInput.remove()
            if (url !== '') {
                this.$message({
                    message: '复制成功！',
                    type: 'success'
                });
            }
        },
        useqrcode() {
            var canvas = document.getElementById('canvas');
            QRCode.toCanvas(canvas, this.qrcode, function (error) {
                if (error) {
                    console.error(error);
                } else {
                }
            });
        },
        gotoinvo() {
            this.$router.push('/invoice')
        },
        gotostaff() {
            this.$router.push('/staff')
        },
        gotoSeting() {
            this.$router.push({
                name: 'setUp',
                query: {
                    id: 1
                }
            })
        },
        gotoCert() {
            this.$router.push('/certificate')
        },
        gotoinfo() {
            this.$router.push('/information')
        },
    },
    mounted() {
        this.useqrcode()
    },
};
</script>
<style lang="less">
@import "../assets/css/css.sass";

.cxewm {
    text-align: center;
    margin-top: 10px;
    font-family: SourceHanSansCN-Regular;
    font-size: 12px;
    font-weight: normal;
    line-height: 16px;
    letter-spacing: 0em;
    color: #444444;
}

.grewm {
    margin-top: 20px;
    width: 74px;
    height: 74px;
    opacity: 1;
    background: #F4F4F4;
    margin: auto;

    .qrcode_user {
        width: 74px !important;
        height: 74px !important;
    }
}

.information {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;

    .kuang {
        width: 130px;
        height: 24px;
        cursor: pointer;
        opacity: 1;
        background: #FFFFFF;
        box-sizing: border-box;
        border: 1px solid #CC3636;
        font-family: SourceHanSansCN-Regular;
        font-size: 12px;
        font-weight: normal;
        line-height: 12px;
        letter-spacing: 0em;
        color: #CC3636;
        text-align: center;
        line-height: 24px;
    }
}

.regInf {
    margin-top: 20px;

    .info {
        display: flex;
        font-family: SourceHanSansCN-Regular;
        font-size: 14px;
        font-weight: normal;
        line-height: 24px;
        letter-spacing: 0em;

        .copy {
            width: 48px;
            height: 14px;
            border-radius: 4px;
            margin-top: 5px;
            opacity: 1;
            margin-left: 10px;
            background: #CC3636;
            font-family: SourceHanSansCN-Regular;
            font-size: 12px;
            font-weight: normal;
            line-height: 12px;
            text-align: center;
            letter-spacing: 0em;
            color: #FFFFFF;
        }
    }
}

.user {
    display: flex;
    justify-content: space-between;
    border-radius: 4px;
    padding: 10px;
    opacity: 1;
    background: #F4F4F4;

    .zcBut {
        width: 72px;
        height: 24px;
        border-radius: 4px;
        opacity: 1;
        background: #CC3636;
        margin-top: 8px;

        div {
            font-family: SourceHanSansCN-Regular;
            font-size: 12px;
            font-weight: normal;
            line-height: 12px;
            letter-spacing: 0em;
            color: #FFFFFF;
            text-align: center;
            line-height: 24px;
        }
    }

    .users {
        display: flex;

        .userx {
            margin-left: 10px;
            font-family: SourceHanSansCN-Regular;
            font-size: 12px;
            font-weight: normal;
            line-height: 20px;
            letter-spacing: 0em;
        }

        .portrait {
            width: 40px;
            height: 40px;
            opacity: 1;
            border-radius: 50%;
            background: #FFFFFF;

        }
    }
}
</style>
<style lang="less" scoped>
.login {
    position: fixed;
    top: 50%;
    left: 50%;
    margin-left: -240px;
    margin-top: -240px;
    z-index: 999;
    width: 480px;
    height: 480px;
    border-radius: 10px;
    opacity: 1;
    background: #ffffff;
    opacity: 1 !important;
    z-index: 999;

    .ph {
        position: fixed;
        top: 75%;
        margin-top: -150px;
        z-index: 999;
        left: 50%;
        margin-left: -60px;
        font-family: PingFangSC-Regular;
        font-size: 18px;
        font-weight: normal;
        line-height: 30px;
        letter-spacing: 0em;
        color: #444444;
    }

    .ewma {
        margin: auto;
        align-items: center;
        text-align: center;

        img {
            position: fixed;
            top: 50%;
            left: 50%;
            margin-left: -150px;
            margin-top: -150px;
            z-index: 999;
            width: 300px;
            height: 300px;
        }
    }

    .sfLogin {
        margin: auto;
        display: flex;
        justify-content: center;
        margin-top: 30px;

        .wxLogin {
            font-family: PingFangSC-Regular;
            font-size: 18px;
            font-weight: normal;
            line-height: 30px;
            letter-spacing: 0em;
            color: #444444;
            margin-left: 5px;
        }
    }

    .line {
        width: 100%;
        margin-top: 40px;
        height: 0px;
        opacity: 1;
        border: 1px solid #E3E3E3;

    }

    .yscr {
        display: flex;
        margin-top: 30px;
        font-family: SourceHanSansCN-Regular;
        font-size: 14px;
        font-weight: normal;
        line-height: 14px;
        letter-spacing: 0em;
        color: #A0A0A0;

        span {
            color: #444444;
        }
    }

    .ljlogin {
        width: 100%;
        height: 48px;
        margin: auto;
        margin-top: 20px;
        background: #CC3636;
        border-radius: 5px;
        text-align: center;
        line-height: 48px;
        font-family: SourceHanSansCN-Regular;
        font-size: 16px;
        font-weight: normal;
        text-align: center;
        letter-spacing: 0em;
        color: #FFFFFF;
        opacity: 1;
    }




    .cz {
        padding: 20px 10px;
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #ece9e9;

        .name {
            font-family: SourceHanSansCN-Regular;
            font-size: 18px;
            font-weight: normal;
            line-height: 32px;
            letter-spacing: 0em;
            color: #444444;
            margin-left: 20px;
        }
    }
}

.bjs {
    opacity: 0.4;
    background-color: rgb(230, 230, 230);
    /* 设置背景颜色 */
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 999;
}
</style>