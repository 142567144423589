<template>
  <div id="app">
    <herder class="pcheder"></herder>
    <router-view v-if="isRouterAlive" :key="key"></router-view>
  </div>
</template>
<script>
import herder from '@/components/herder.vue'
export default{
  components:{
    herder
  },
  provide(){
    return{
      reload:this.reload
    }
  },
  data() {
    return {
      herdShow:true,
      isRouterAlive:true
    }
  },
  methods:{
    reload(){
      this.isRouterAlive=false,
      this.$nextTick(function(){
        this.isRouterAlive=true
      })
    }
  },
  mounted(){
    
  },
  computed: {
    key() {
      return this.$route.path + Math.random();
    }
  },
}
</script>

<style lang="less">
#app {
  font-family: PingFangSC-Regular;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.flex{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.flex_c{
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
}
.flex_s{
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
}
.flex_b{
  display: flex;
  justify-content: space-between;
}
.flex_a{
  display: flex;
  justify-content: space-around;
}
.flex_e{
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.flex_v{
  display: flex;
  flex-wrap: wrap;
  flex-direction: column-reverse;
  justify-content: center;
  align-items: center;
}
.flex_vs{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.flex_d{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.transition{
  transition: 0.5s all;
}
.br{
  background: #cc3636;
}
.bs-font{
  color: #ffffff;
}
@media screen and (max-width: 900px)  {
  .pcheder{
    display: none;
  }
}
.ql-size-small{
  font-size: 10px;
}
.ql-size-huge{
  font-size: 32px;
}
.ql-size-large{
  font-size: 18px;
}
</style>
