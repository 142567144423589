<template>
  <div class="home">
    <div class="footer">
      <div class="footerTxt">© 2022 四川省网络营销从业人员诚信管理平台 蜀ICP备08106674号-1</div>
    </div>
  </div>
</template>
  
<script>

export default {
  name: 'footers',
}
</script>
<style lang="less" scoped>
.footer {
  width: 100%;
  height: 80px;
  opacity: 1;
  background: #CC3636;
  z-index: 9999;
}

@media screen and (min-width: 501px) {
  .footerTxt {
    text-align: center;
    line-height: 80px;
    font-family: PingFangSC-Medium;
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0em;
    color: #FFFFFF;
  }
}

@media screen and (max-width: 500px) {
  .footerTxt {
    text-align: center;
    padding: 0 40px;
    line-height: 40px;
    font-family: PingFangSC-Medium;
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0em;
    color: #FFFFFF;
  }
}
</style>
  