<template>
  <div class="home" v-show="herdShow">
    <div class="nav">
      <div style="display: flex; justify-content: space-between">
        <div style="display: flex">
          <div class="logo">logo</div>
          <div class="titles" style="font-size: 24px; font-weight: bold; margin-left: 10px;">
            网络营销从业人员信用档案注册管理平台
          </div>
        </div>

        <div class="loginBUt" v-if="lonShow">
          <div class="loginTxt" @click="logShow" style="line-height: 36px;">登录</div>
          <!-- <div class="line"></div> -->

        </div>
        <div v-else style="display: flex;">
          <user :getInfo="getInfo"></user>
          <div style="line-height: 28px; font-size:12px; margin-left: 10px; cursor: pointer; " @click="delCookie">退出
          </div>
        </div>
      </div>
      <div class="hLine"></div>
      <div class="menu">
        <el-menu text-color="#6d6c6c" :default-active="activeIndex" class="el-menu-demo" mode="horizontal"
          @select="handleSelect" active-text-color="#CC3636" router>
          <el-menu-item v-for="(item, index) in list" :index="item.path">{{
            item.name
          }}</el-menu-item>
        </el-menu>
        <div style="margin-top: 15px">
          <el-input :placeholder=tisder v-model="input3" class="input-with-select" @change="seacth(select, input3)">
            <el-select v-model="select" slot="prepend" placeholder="请选择" @change="selectchange">
              <el-option label="信用档案" value="2"></el-option>
              <el-option label="资讯搜索" value="1"></el-option>
            </el-select>
            <i slot="suffix" class="el-input__icon el-icon-search" @click="seacth(select, input3)"></i>
          </el-input>
        </div>
      </div>
    </div>
    <div>
      <div class="bjs" v-show="bjShow"></div>
      <div class="login" v-show="wxLogShow">
        <div class="cz">
          <div></div>
          <div class="name" style="margin-left: 20px">微信登录</div>
          <div @click="guanbi">
            <img src="../../public/images/login/tuic.png" alt="" />
          </div>
        </div>
        <div class="ewma">
          <!-- <img src="../../public/images/login/erwm.png" alt="" /> -->
          <!-- <canvas id="canvase" class="qrcode" ref="imagecode"></canvas> -->
          <wxlogin :appid="appid" scope="snsapi_login" :theme="'black'"  :redirect_uri="redirect_uri" :href="'data:text/css;base64,LmltcG93ZXJCb3ggLnFyY29kZSB7d2lkdGg6IDIwMHB4O30KLmltcG93ZXJCb3ggLnRpdGxlIHtkaXNwbGF5OiBub25lO30KLmltcG93ZXJCb3ggLmluZm8ge3dpZHRoOiAyMDBweDt9Ci5zdGF0dXNfaWNvbiB7ZGlzcGxheTogbm9uZX0KLmltcG93ZXJCb3ggLnN0YXR1cyB7dGV4dC1hbGlnbjogY2VudGVyO30g'" rel="external nofollow"></wxlogin>
        </div>
        <!-- <div class="ph" @click="phShow">使用手机号登陆</div> -->
      </div>
      <div class="login" v-show="loginShow">
        <div class="cz">
          <div></div>
          <div class="name">用户登录</div>
          <div @click="guanbi">
            <img src="../../public/images/login/tuic.png" alt="" />
          </div>
        </div>
        <div class="loginLr">
          <Input v-model="form.phone" size="large" placeholder="请输入手机号"></Input>

          <div style="margin-top: 30px">
            <Input size="large" v-model="form.verificationCode" placeholder="请输入验证码">
            <Button type="error" slot="append" @click="Qcode">{{ Captcha == 0 ? "验证码" : Captcha }}</Button>
            </Input>
            <div class="ljlogin" @click="Logintext" style="cursor: pointer;">立即登录</div>
            <div class="yscr">
              <Checkbox v-model="single"></Checkbox>
              <div style="line-height: 20px ;cursor: pointer;">
                我已阅读并同意<span @click="fuxy">《用户服务协议》</span>和
                <span @click="ysxy">《隐私政策》</span>
              </div>
            </div>
            <div class="line"></div>
            <div class="sfLogin" @click="wxLogins" style="cursor: pointer;">
              <img src="../../public/images/login/wx.png" alt="" />
              <div class="wxLogin" >微信登录</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
import user from "@/components/user.vue";
import { login, sendSms, wxLogin } from "@/api/login";
import wxlogin from "vue-wxlogin";
import QRCode from "qrcode"
import { setToken, getToken, setUserfo, setcodefo,setMfo } from "@/util/auth";
import { zsID } from '@/api/archives'
import { search } from '@/api/search'
export default {
  inject: ['reload'],
  name: "herder",
  components: {
    user,
    wxlogin
  },
  data() {
    return {
      Captcha: "验证码",
      tisder: '请输入证书ID',
      circleUrl:
        "https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png",
      squareUrl:
        "https://cube.elemecdn.com/9/c2/f0ee8a3c7c9638a54940382568c9dpng.png",
      lonShow: true,
      herdShow: true,
      input: "",
      form: {},
      value: "",
      pageSize: 3,
      currentPage: 1,
      getInfo: {},
      single: false,
      loginShow: false,
      bjShow: false,
      wxLogShow: false,
      activeIndex: "/",
      input3: "",
      select: "信用档案",
      regInfList: [
        { title: "注册时间：", info: "2022.11.01", copy: "" },
        { title: "有效期至：", info: "2023.11.01", copy: "" },
        { title: "证书ID：", info: "SC202200000", copy: "复制" },
      ],
      list: [
        { name: "首页", path: "/" },
        { name: "平台介绍", path: "/about" },
        { name: "政策法规", path: "/policy" },
        { name: "行业资讯", path: "/info" },
        { name: "我要注册", path: "/enroll" },
        { name: "信用档案", path: "/archives" },
        { name: "在线学习", path: "/study" },
      ],
      appid:'',
      snsapi_login:'',
      redirect_uri:''
    };
  },
  created() {
    
    if (getToken() !== undefined) {
      this.lonShow = false
    }
  },
  methods: {
    wxcode() {
      
    },
    ysxy() {
      this.loginShow = false;
      this.bjShow = false;
      this.wxLogShow = false;
      this.$router.push({
        name: 'agreement',
        query: {
          id: 6,
        }
      })
    },
    fuxy() {
      this.loginShow = false;
      this.bjShow = false;
      this.wxLogShow = false;
      this.$router.push({
        name: 'agreement',
        query: {
          id: 5,
        }
      })
    },
    delCookie() {
      var cookies = document.cookie.split(";");
      for (var i = 0; i < cookies.length; i++) {
        var cookie = cookies[i];
        var eqPos = cookie.indexOf("=");
        var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
        document.cookie =
          name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/";
      }
      if (cookies.length > 0) {
        for (var i = 0; i < cookies.length; i++) {
          var cookie = cookies[i];
          var eqPos = cookie.indexOf("=");
          var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
          var domain = location.host.substr(location.host.indexOf("."));
          document.cookie =
            name +
            "=;expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/; domain=" +
            domain;
        }
      }
      this.$router.go(0)
      location.reload()
      this.$message({
        message: '退出登录成功！',
        type: 'warning'
      });
      this.$router.push({
        name: 'home',
      })
    },
    sendSms() {
      sendSms(this.form.phone).then((res) => {
      })

    },
    Qcode() {
      if (this.Captcha == '验证码') {
        this.Captcha = 60;
        this.sendSms()
        let time = setInterval(() => {
          if (this.Captcha === 0) {
            this.Captcha = '验证码'
            clearInterval(time);
          } else {
            this.Captcha--;
          }
        }, 1000);
      } else {
        this.open2()
      }

    },
    open2() {
      this.$message.error('请稍后再试！');
    },
    open() {
      this.$message.error('对不起，没有找到你想要的哦！');
    },
    searchs() {


    },
    selectchange(e) {
      if (e == 1) {
        this.tisder = '请输入关键字'
      } else {
        this.tisder = '请输入证书ID'
      }
    },
    Logintext() {
      var reg = /^1[3-9]\d{9}$/;
      var logo = reg.test(this.form.phone)
      if (this.single == false) {
        this.$message.error("请勾用户服务选协议");
      } else if (logo == false) {
        this.$message.error("手机号格式错误！");
      } else if (this.form.verificationCode == '' || this.form.verificationCode == undefined || this.form.verificationCode == null) {
        this.$message.error("请输入验证码");
      } else {
        login(this.form).then((res) => {

          if (res.message == "该手机号未注册") {
            this.$message.error('该手机号未注册');
          } else if (res.message == "该手机号已注销") {
            this.$message.error('该账号已注销');
          }else if(
            res.result.user.state==3
          ){
            this.$message.error('该账号已注销,请联系管理员');
          }
           else {
            this.bjShow = false;
            this.loginShow = false;
            this.getInfo = res.result.user
            this.$store.state.user.getInfoUser = this.getInfo
            setUserfo(JSON.stringify(this.getInfo))
            setcodefo(res.result.QRCode)
            setToken(res.result.token)
            setMfo(JSON.stringify(res.result.mechanism))
            this.lonShow = false
            this.$message({
              message: '登录成功！',
              type: 'success'
            });
          }
        });
      }
    },
    gotoinvo() {
      this.$router.push("/invoice");
    },
    seacth(i, content) {
      if (content != '' && i == "资讯搜索" || i == 1 && content != '') {
        search({
          nieron: content,
          type: 0,
          pageSize: 1,
          currentPage: 10,
        }).then(res => {
          if (res.result.records == '') {
            this.input3 = ''
            this.$router.push({ name: "search", query: { cont: content } });
            this.open()
          } else {
            this.input3 = ''
            this.$router.push({ name: "search", query: { cont: content } });
          }

        })

      } else if (i == 2 && content != '') {
        zsID(
          {
            certificateId: content
          }
        ).then((res) => {
          if (res.result.certificateId == null || res.result.certificateId == '') {
            this.open()
            this.input3 = ''
            this.$router.push({
              name: 'arDtails',
              query: {
                id: content,
              }
            })
          } else {
            this.input3 = ''
            this.$router.push({
              name: 'arDtails',
              query: {
                id: content,
              }
            })
          }
        })
      } else {
        return
      }
    },
    gotostaff() {
      this.$router.push("/staff");
    },
    gotoSeting() {
      this.$router.push({
        name: 'setUp',
        query: {
          id: 1,
        }
      })
    },
    gotoCert() {
      this.$router.push("/certificate");
    },
    gotoinfo() {
      this.$router.push("/information");
    },
    phShow() {
      this.loginShow = true;
      this.wxLogShow = false;
      this.bjShow = true;
    },
    wxLogins() {
      this.wxcode()
      this.loginShow = false;
      this.wxLogShow = true;
      this.bjShow = true;
    },
    guanbi() {
      this.loginShow = false;
      this.bjShow = false;
      this.wxLogShow = false;
    },
    logShow() {
      this.loginShow = true;
      this.bjShow = true;
    },
    handleSelect(key, keyPath) {
    },
  },
  mounted() { 
    wxLogin().then((res) => {
      console.log(res,8856)
        this.redirect_uri=res.result.redirect_uri
        this.snsapi_login=res.result.scope
        this.appid=res.result.appid
      })
  },
};
</script>
<style lang="less">
@import "../assets/css/css.sass";
.impowerBox .qrcode{
  width: 300px !important;
  height: 300px !important;
}
.el-icon-arrow-up:before {
  content: "\e790" !important;
  color: #cc3636;
}

.el-dropdown-menu__item,
.el-menu-item {
  font-size: 16px !important;
  font-weight: bold !important;
  font-family: SourceHanSansCN-Regular !important;
}

.cxewm {
  text-align: center;
  margin-top: 10px;
  font-family: SourceHanSansCN-Regular;
  font-size: 12px;
  font-weight: normal;
  line-height: 16px;
  letter-spacing: 0em;
  color: #444444;
}

.ewm {
  margin-top: 20px;
  width: 74px;
  height: 74px;
  opacity: 1;
  background: #f4f4f4;
  margin: auto;
}

.information {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;

  .kuang {
    width: 130px;
    height: 24px;
    opacity: 1;
    background: #ffffff;
    box-sizing: border-box;
    border: 1px solid #cc3636;
    font-family: SourceHanSansCN-Regular;
    font-size: 12px;
    font-weight: normal;
    line-height: 12px;
    letter-spacing: 0em;
    color: #cc3636;
    text-align: center;
    line-height: 24px;
  }
}

.regInf {
  margin-top: 20px;

  .info {
    display: flex;
    font-family: SourceHanSansCN-Regular;
    font-size: 14px;
    font-weight: normal;
    line-height: 24px;
    letter-spacing: 0em;

    .copy {
      width: 48px;
      height: 14px;
      border-radius: 4px;
      margin-top: 5px;
      opacity: 1;
      margin-left: 10px;
      background: #cc3636;
      font-family: SourceHanSansCN-Regular;
      font-size: 12px;
      font-weight: normal;
      line-height: 12px;
      text-align: center;
      letter-spacing: 0em;
      color: #ffffff;
    }
  }
}

.user {
  display: flex;
  justify-content: space-between;
  border-radius: 4px;
  padding: 10px;
  opacity: 1;
  background: #f4f4f4;

  .zcBut {
    width: 72px;
    height: 24px;
    border-radius: 4px;
    opacity: 1;
    background: #cc3636;
    margin-top: 8px;

    div {
      font-family: SourceHanSansCN-Regular;
      font-size: 12px;
      font-weight: normal;
      line-height: 12px;
      letter-spacing: 0em;
      color: #ffffff;
      text-align: center;
      line-height: 24px;
    }
  }

  .users {
    display: flex;

    .userx {
      margin-left: 10px;
      font-family: SourceHanSansCN-Regular;
      font-size: 12px;
      font-weight: normal;
      line-height: 20px;
      letter-spacing: 0em;
    }

    .portrait {
      width: 40px;
      height: 40px;
      opacity: 1;
      border-radius: 50%;
      background: #ffffff;
    }
  }
}

.loginLr {
  padding: 20px 40px;
}

.ivu-input-group-append .ivu-btn,
.ivu-input-group-prepend .ivu-btn {
  border-color: #cc3636 !important;
  background-color: #cc3636 !important;
  color: inherit;
  width: 160px;
  color: #ffffff !important;
  // margin: -6px -7px;
}

// .yaTxt {
//   width: 160px;
//   font-family: SourceHanSansCN-Regular;
//   font-size: 16px;
//   font-weight: normal;
//   line-height: 16px;
//   letter-spacing: 0em;
//   margin-left: 30px;
//   background: #cc3636 !important;
//   color: #ffffff !important;
// }

.el-input__inner {
  border: 1px solid #cc3636 !important;
  border-radius: 0 !important;
}

.el-select .el-input {
  width: 120px;
  background: rgba(204, 54, 54, 0.11);
  color: #cc3636;
}

// .input-with-select .el-input-group__prepend {
//   background-color: #fff;
// }
</style>
<style lang="less" scoped>
.login {
  position: fixed;
  top: 50%;
  left: 50%;
  margin-left: -240px;
  margin-top: -240px;
  z-index: 999;
  width: 480px;
  height: 480px;
  border-radius: 10px;
  opacity: 1;
  background: #ffffff;
  opacity: 1 !important;
  z-index: 999;

  .ph {
    position: fixed;
    top: 75%;
    margin-top: -150px;
    z-index: 999;
    left: 50%;
    margin-left: -60px;
    font-family: PingFangSC-Regular;
    font-size: 18px;
    font-weight: normal;
    line-height: 30px;
    letter-spacing: 0em;
    color: #444444;
  }

  .ewma {
    margin: auto;
    align-items: center;
    text-align: center;

    img {
      position: fixed;
      top: 50%;
      left: 50%;
      margin-left: -150px;
      margin-top: -150px;
      z-index: 999;
      width: 300px;
      height: 300px;
    }
  }

  .sfLogin {
    margin: auto;
    display: flex;
    justify-content: center;
    margin-top: 30px;

    .wxLogin {
      font-family: PingFangSC-Regular;
      font-size: 18px;
      font-weight: normal;
      line-height: 30px;
      letter-spacing: 0em;
      color: #444444;
      margin-left: 5px;
    }
  }

  .line {
    width: 100%;
    margin-top: 40px;
    height: 0px;
    opacity: 1;
    border: 1px solid #e3e3e3;
  }

  .yscr {
    display: flex;
    margin-top: 30px;
    font-family: SourceHanSansCN-Regular;
    font-size: 14px;
    font-weight: normal;
    line-height: 14px;
    letter-spacing: 0em;
    color: #a0a0a0;

    span {
      color: #444444;
    }
  }

  .ljlogin {
    width: 100%;
    height: 48px;
    margin: auto;
    margin-top: 20px;
    background: #cc3636;
    border-radius: 5px;
    text-align: center;
    line-height: 48px;
    font-family: SourceHanSansCN-Regular;
    font-size: 16px;
    font-weight: normal;
    text-align: center;
    letter-spacing: 0em;
    color: #ffffff;
    opacity: 1;
  }

  .cz {
    padding: 20px 10px;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #ece9e9;

    .name {
      font-family: SourceHanSansCN-Regular;
      font-size: 18px;
      font-weight: normal;
      line-height: 32px;
      letter-spacing: 0em;
      color: #444444;
      margin-left: 20px;
    }
  }
}

.loginTxt {
  cursor: pointer;
}

.bjs {
  opacity: 0.4;
  background-color: rgb(230, 230, 230);
  /* 设置背景颜色 */
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 999;
}
</style>