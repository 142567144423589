<template>
  <div class="bj">
    <div class="xzwl">
      <div class="leir">
        <div class="letone">
          <div class="Lname">行业资讯</div>
          <div class="gdBut" @click="gotoInfo">查看更多 ></div>
        </div>
        <div class="letone">
          <div class="Lname">政策法规</div>
          <div class="gdBut" @click="gopolicy">查看更多 ></div>
        </div>
      </div>
      <div class="leir">
        <div style="width: 48%; line-height: 120px;">
          <div class="letone"
            style="width: 100%; line-height: 120px; padding: 10px 0; display: flex; justify-content: space-between;"
            v-for="(item, index) in hyList" :key="index" @click="gohyDetail(item)">
            <li class="title">{{ item.name }}</li>
            <div class="time">{{ item.updateTime | date('yyyy-MM-dd ') }}</div>
          </div>
        </div>
        <div style="width: 48%; line-height: 120px;">
          <div class="letone"
            style="width: 100%; line-height: 120px; padding: 10px 0; display: flex; justify-content: space-between;"
            v-for="(item, index) in iPolist" :key="index" @click="gofgDetail(item)">
            <li class="title">{{ item.name }}</li>
            <div class="time">{{ item.updateTime | date('yyyy-MM-dd ') }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="bjLin">
      <el-divider></el-divider>
    </div>
    <div>
      <div class="leir" style="width: 100%;">
        <div class="letone">
          <div class="Lname">平台说法</div>
          <div class="gdBut" @click="gostaGend">查看更多 ></div>
        </div>
        <div class="letone">
          <div class="Lname">平台活动</div>
          <div class="gdBut" @click="gohdGend">查看更多 ></div>
        </div>
      </div>
      <div class="leir">
        <div style="width: 48%; line-height: 120px;">
          <div class="letone"
            style="width: 100%; line-height: 120px; padding: 10px 0; display: flex; justify-content: space-between;"
             v-for="(item, index) in iPlaIst" :key="index" @click="gosfDetail(item)">
            <li class="title">{{ item.name }}</li>
            <div class="time">{{ item.createTime | date('yyyy-MM-dd ') }}</div>
          </div>
        </div>
        <div style="width: 48%; line-height: 120px;">
          <div class="letone"
            style="width: 100%; line-height: 120px; padding: 10px 0; display: flex; justify-content: space-between;"
            v-for="(item, index) in acList" :key="index" @click="goshdetail(item)">
            <li class="title">{{ item.name }}</li>
            <div class="time">{{ item.createTime | date('yyyy-MM-dd') }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="xzwz" >
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane :label="item.label" :name="item.name" v-for="(item, index) in list" :index="item.name">
          <div style="display:flex; justify-content: space-between; ">
            <div style="width: 48%;">
              <tr v-for="(it, i) in kcLIst" :key="i" style=" display: flex; justify-content: space-between;" @click="gotoDtail(item,it)">
                <li class="titleTxts">{{ it.name }}</li>
                <div class="timeTxt">{{ it.createTime | date('yyyy-MM-dd') }}</div>
              </tr>
            </div>
            <div style="width: 48%;">
              <tr v-for="(it, i) in kcLIst2" :key="i" style=" display: flex; justify-content: space-between;" @click="gotoDtail(item,it)">
                <li class="titleTxts">{{ it.name }}</li>
                <div class="timeTxt">{{ it.createTime | date('yyyy-MM-dd') }}</div>
              </tr>
            </div>
          </div>

        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>
    
<script>
import { iIndustry, iPolicies, iPlatformt, Activit, Classification, Controller } from '@/api/home'
export default {
  name: 'titles',
  data() {
    return {
      activeName: 'first',
      hyList: [],
      iPolist: [],
      iPlaIst: [],
      acList: [],
      list: [],
      kcLIst: [],
      kcLIst2: []
    };
  },
  methods: {
    gotoDtail(item, it) {
            this.$router.push({
                name: 'studetails',
                query: {
                    id: it.id,
                    classid: item.id
                }
            })
        },
    gohdGend(){
      this.$router.push({
        name: 'activitygd',
      })
    },
    gostaGend(){
      this.$router.push({
        name: 'statement',
      })
    },
    goshdetail(item) {
      this.$router.push({
        name: 'activity',
        query: {
          id: item.id,
        }
      })
    },
    gosfDetail(item) {
      this.$router.push({
        name: 'stateDtail',
        query: {
          id: item.id,
        }
      })
    },
    gofgDetail(item) {
      this.$router.push({
        name: 'policyDetail',
        query: {
          id: item.id,
        }
      })
    },
    gohyDetail(item) {
      this.$router.push({
        name: 'infoDtail',
        query: {
          id: item.id,
        }
      })
    },
    Controller() {
      var a = []
      var b = []
      Controller(
        this.kcID
      ).then((res) => {
        res.result.forEach((item, index) => {
          if ((index + 1) % 2 == 0) {
            a.push(item)
          } else {
            b.push(item)
          }
        })
        this.kcLIst = b
        this.kcLIst2 = a
      })
    },
    Classification() {
      var a = []
      Classification().then((res) => {
        this.kcID = res.result[0].id
        res.result.forEach((item, index) => {
          a.push({
            name: index == 0 ? 'first' : index == 1 ? 'second' : index == 2 ? 'third' : index == 2 ? 'four' : 'five',
            label: item.name,
            id: item.id
          })
        })
        this.list = a
        this.Controller()
      })

    },
    Activit() {
      Activit().then((res) => {
        let b = res.result.slice(0, 5);
        this.acList = b
      })

    },
    iPlatformt() {
      iPlatformt().then((res) => {
        let b = res.result.slice(0, 5);
        this.iPlaIst = b
      })

    },
    iPolicies() {
      iPolicies().then((res) => {
        let b = res.result.slice(0, 5);
        this.iPolist = b
      })

    },
    baocun() {
      iIndustry().then((res) => {
        let b = res.result.slice(0, 5);
        this.hyList = b
      })

    },
    gopolicy() {
      this.$router.push('/policy')
    },
    gotoInfo() {
      this.$router.push('/info')
    },
    handleClick(tab) {
      var a = ''
      this.list.forEach((item, index) => {
        if (tab.label == item.label) {
          a = item.id
        }
      })
      this.kcID = a
      this.Controller()
    }
  },
  mounted() {
    this.baocun()
    this.iPolicies()
    this.iPlatformt()
    this.Activit()
    this.Classification()
  },
}
</script>
<style lang="less" scoped>
@import "../../assets/css/css.sass";
</style>
<style>
li::marker {
  color: #A0A0A0;
}

.el-divider {
  background-color: #CC3636 !important;
}

.el-tabs__nav-wrap::after {
  height: 1px !important;
}

.el-tabs__item {
  font-size: 16px !important;
}

.el-tabs__item.is-active {
  color: #CC3636 !important;
}

.el-tabs__active-bar {
  background: #CC3636 !important;
}

.el-tabs__item:hover {
  color: #CC3636 !important;
}
</style>
    