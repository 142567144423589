import requset from '@/util/requset'
export function login(data) {
    return requset({
        url: '/ruoyipc/userManagementController/login',
        method: 'post',
        data
    })
}
export function sendSms(data) {
    return requset({
        url: '/api/sendSms/?phone=' + data,
        method: 'post',
    })
}
export function cancellation(data) {
    return requset({
        url: '/loggedIn/cancellation',
        method: 'post',
        data
    })
}
export function wxLogin(data) {
    return requset({
        url: '/wx/login/getLoginParam',
        method: 'GET',
        data
    })
}
export function callback(data,state) {
    return requset({
        url: `/wx/login/callback?code=${data}&state=${state}`,
        method: 'GET',
    })
}
export function bangd(data) {
    return requset({
        url: '/home/bangd',
        method: 'POST',
        data
    })
}