<template>
  <div>
    <div class="hpad">
      <div class="htitle">
        <div class="titleName">行业资讯</div>
        <div class="gdBut"  @click="gotoInfo">更多 ></div>
      </div>
      <div class="htitle" style="margin-top: 20px;"  v-for="(item, index) in hyList" :key="index" @click="gohyDetail(item)">
        <li class="title">{{ item.name.slice(0, 5)  }}</li>
          <div class="time">{{ item.updateTime | date('yyyy-MM-dd') }}</div>
      </div>
    </div>
    <div class="hpad">
      <div class="htitle">
        <div class="titleName">政策法规</div>
        <div class="gdBut" @click="gopolicy">更多 ></div>
      </div>
      <div class="htitle" style="margin-top: 20px;" v-for="(item, index) in iPolist" :key="index" @click="gofgDetail(item)">
        <li class="title">{{ item.name.slice(0, 5)  }}</li>
        <div class="time">{{ item.updateTime | date('yyyy-MM-dd') }}</div>
      </div>
    </div>
    <div class="hpad">
      <div class="htitle">
        <div class="titleName">平台活动</div>
        <div class="gdBut" @click="gohdGend">更多 ></div>
      </div>
      <div class="htitle" style="margin-top: 20px;" v-for="(item, index) in acList" :key="index"
      @click="goshdetail(item)">
        <li class="title">{{ item.name }}</li>
        <div class="time">{{ item.createTime | date('yyyy-MM-dd ') }}</div>
      </div>
    </div>
    <div class="hpad">
      <div class="htitle">
        <div class="titleName">平台说法</div>
        <div class="gdBut" @click="gostaGend">更多 ></div>
      </div>
      <div class="htitle" style="margin-top: 20px;" v-for="(item, index) in  iPlaIst" :key="index"
      @click="gosfDetail(item)">
        <li class="title">{{ item.name }}</li>
        <div class="time">{{ item.createTime | date('yyyy-MM-dd ') }}</div>
      </div>
    </div>
    <div class="xzwz">
      <el-tabs v-model="activeName" @tab-click="handleClick" style="height:300px;background: #ffffff;">
        <el-tab-pane :label="item.label" :name="item.name" v-for="(item, index) in list" :index="item.name"
          style="width:90%;margin:0 auto;">
          <div style="display:flex; justify-content: space-between; ">
            <div style="width: 96%; ">
              <tr v-for="(it, i) in kcLIst" :key="i" style=" display: flex; justify-content: space-between;" @click="gotoDtail(item,it)">
                <li class="titleTxts">{{ it.name }}</li>
                <div class="timeTxt">{{ it.createTime | date('yyyy-MM-dd') }}</div>
              </tr>
            </div>
          </div>

        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>
    
<script>
import { iIndustry, iPolicies, iPlatformt, Activit, Classification, Controller } from '@/api/home'
export default {
  // props:{
  //   list:{
  //     typeof:Array,
  //   }
  // },
  name: 'h5Tiles',
  data() {
    return {
      activeName: 'first',
      hyList: [],
      iPolist: [],
      iPlaIst: [],
      acList: [],
      list: [],
      kcLIst: [],
    };
  },

  mounted() {
    this.baocun()
    this.iPolicie()
    this.iPlatformt()
    this.Activit()
    this.Classification()
  },
  methods: {
    gotoDtail(item, it) {
            this.$router.push({
                name: 'studetails',
                query: {
                    id: it.id,
                    classid: item.id
                }
            })
        },
    goshdetail(item) {
      this.$router.push({
        name: 'activity',
        query: {
          id: item.id,
        }
      })
    },
    gofgDetail(item) {
      this.$router.push({
        name: 'policyDetail',
        query: {
          id: item.id,
        }
      })
    },
    gohdGend(){
      this.$router.push({
        name: 'activitygd',
      })
    },
    gostaGend(){
      this.$router.push({
        name: 'statement',
      })
    },
    gopolicy() {
      this.$router.push('/policy')
    },
    gohyDetail(item) {
      this.$router.push({
        name: 'infoDtail',
        query: {
          id: item.id,
        }
      })
    },
    handleClick(tab) {
      var a = ''
      this.list.forEach((item, index) => {
        if (tab.label == item.label) {
          a = item.id
        }
      })
      this.kcID = a
      this.Controller()
    },
    Classification() {
      var a = []
      Classification().then((res) => {
        this.kcID = res.result[0].id
        res.result.forEach((item, index) => {
          a.push({
            name: index == 0 ? 'first' : index == 1 ? 'second' : index == 2 ? 'third' : index == 2 ? 'four' : 'five',
            label: item.name,
            id: item.id
          })
        })
        this.list = a
        this.Controller()
      })
    },
    Controller() {
      var a = []
      var b = []
      Controller(
        this.kcID
      ).then((res) => {
        this.kcLIst = res.result
      })
    },
    Activit() {
      Activit().then((res) => {
        let b = res.result.slice(0, 5);
        this.acList = b
      })
    },
    gosfDetail(item) {
      this.$router.push({
        name: 'stateDtail',
        query: {
          id: item.id,
        }
      })
    },
    iPolicie() {
      iPolicies().then((res) => {
        let b = res.result.slice(0, 5);
        this.iPolist = b
      })
    },
    iPlatformt() {
      iPlatformt().then((res) => {
        let b = res.result.slice(0, 5);
        this.iPlaIst = b
      })
    },
    gopolicy() {
      this.$router.push('/policy')
    },
    gotoInfo() {
      this.$router.push('/info')
    },
    baocun() {
      iIndustry().then((res) => {
        this.hyList = res.result.slice(0, 5);
      })
    },
  }
}
</script>
<style lang="less" scoped>

@import "../../assets/css/css.sass";
.title{
  line-height: 10px;
    margin-left: 5px;
    opacity: 1;
    font-family: PingFangSC-Regular;
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0em;
    color: #444444;
}
.titleTxts{
  line-height: 30px;
    margin-left: 5px;
    opacity: 1;
    font-family: PingFangSC-Regular;
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0em;
    color: #444444;
}
.timeTxt{
    font-family: PingFangSC-Regular;
    font-size: 14px;
    font-weight: normal;
    line-height: 14px;
    letter-spacing: 0em;
}
</style>
    