import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import wx from 'weixin-js-sdk'
import 'element-ui/lib/theme-chalk/index.css';
import iView from 'iview';
import 'iview/dist/styles/iview.css'; 
import Vant from 'vant'
import 'vant/lib/index.css'
import { datePipe } from '../src/util/time';
Vue.filter('date', datePipe);
Vue.use(Vant)
Vue.use(ElementUI);
Vue.use(iView);
Vue.config.productionTip = false
Vue.prototype.$wx = wx
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
