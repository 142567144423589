import Cookies from 'js-cookie'

const TokenKey = 'token'
const Userfo = 'Userfo'
const Codefo = 'Codefo'
const mfo = 'mfo'
export function getToken() {
  return Cookies.get(TokenKey)
}

export function setToken(token) {
  return Cookies.set(TokenKey, token)
}

export function removeToken() {
  return Cookies.remove(TokenKey)
}
export function getUserfo() {
  return Cookies.get(Userfo)
}
export function getcodefo(Code) {
  return Cookies.get(Codefo, Code)
}
export function getMfo(mechanism) {
  return Cookies.get(mfo, mechanism)
}
export function setcodefo(Code) {
  return Cookies.set(Codefo, Code)
}
export function setUserfo(User) {
  return Cookies.set(Userfo, User)
}
export function setMfo(mechanism) {
  return Cookies.set(mfo, mechanism)
}
export function setmenu(menu) {
  return Cookies.set('menu', menu)
}