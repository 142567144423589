<template>
  <div>
    <div style="position: fixed; z-index: 9999; width: 100%; top: 0;" v-show="herdShow" class="h5hedera">
      <div class="h5heder">
        <div v-if="butShow" @click="sigButs" style="margin-top: 3px; z-index: 9999999;">
          <img src="../../public/images/heder/san.png" alt="">
        </div>
        <div v-else @click="fillBut">
          <img style="height: 20px; margin-top: 8px;" src="../../public/images/heder/gb.png" alt="">
        </div>

        <div style=" width: 68%; margin-top: 5px;" class="hInp">
          <el-input :placeholder=tisder v-model="input3" class="input-with-select" @change="seacth(select, input3)">
            <el-select v-model="select" slot="prepend" placeholder="请选择" @change="selectchange">
              <el-option label="信用档案" value="1"></el-option>
              <el-option label="资讯搜索" value="2"></el-option>
            </el-select>
            <i slot="suffix" class="el-input__icon el-icon-search" @click="seacth(select, input3)"></i>
          </el-input>
        </div>
        <div v-if="h5Login" class="login" @click="login"><span>登录</span></div>
        <div v-else slot="reference" @click="gotoUser">
          <el-col :span="12">
            <div style="margin-top: 5px;">
              <div>
                <el-avatar size="small" :src="circleUrl"></el-avatar>
              </div>
            </div>
          </el-col>
        </div>

      </div>
      <div v-show="sidShow">
        <van-sidebar v-model="activeKey">
          <van-sidebar-item v-for="(item, index) in list" :index="item.path" :title=item.name @click="onChange(item)" />
        </van-sidebar>
      </div>
    </div>
    <div class="block" style="">
      <el-carousel height="34vw">
        <el-carousel-item v-for="(item, index) in iCarList" :key="index">
          <a :href=item.link target="_blank"><img ref="imgHeight" :src="item.advertisingCover" width="100%"
              height="100%" object-fit="cover"></a>
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="ability">
      <div class="h5s">
        <div class="flex_d">
          <div class="res" style="position: sticky;" @click="gotoreg">
            <div>
              <div>
                <img style="position: absolute; bottom: 0;" src="../../public/images/home/rer.png" alt="">
              </div>
              <div class="reg">
                <div class="registers">我要注册</div>
                <div class="lines"></div>
                <div class="retex">Online </div>
                <div class="retex">registration </div>
              </div>
            </div>
          </div>
          <div>
            <div class="res" style="position: sticky; margin-top: 10px;">
              <div>
                <div><img style="position: absolute; bottom: 0;" src="../../public/images/home/styjc.png" alt="">
                </div>
                <div class="reg" @click="gotoStudy">
                  <div class="registers">在线学习</div>
                  <div class="lines"></div>
                  <div class="retex">learning </div>
                  <!-- <div class="retex">registration </div> -->
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="flex_d">
          <div class="kf">
            <div @click="gotoArch">
              <div class="kfTxt">信用档案</div>
              <div class="line"></div>
              <div class="txt">complaint</div>
            </div>
            <div style="position: sticky;">
              <div><img style="position: absolute; bottom: 0; right: 0;" src="../../public/images/home/dajc.png" alt="">
              </div>
            </div>
          </div>
          <div class="kf">
            <div @click="goset">
              <div class="kfTxt">在线客服</div>
              <div class="line"></div>
              <!-- <div class="txt">complaint</div> -->
            </div>
            <div style="position: sticky;">
              <div><img style="position: absolute; bottom: 0; right: 0;" src="../../public/images/home/kf.png" alt="">
              </div>
            </div>
          </div>
          <div class="kf" @click="gotofeed">
            <div>
              <div class="kfTxt">意见反馈</div>
              <div class="line"></div>
              <!-- <div class="txt">complaint</div> -->
            </div>
            <div style="position: sticky;">
              <div><img style="position: absolute; bottom: 0; right: 0;" src="../../public/images/home/yjfk.png" alt="">
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="wantRegister" @click="gotoreg">
        <div style="position: sticky;">
          <img style="position: absolute; bottom: 0;" src="../../public/images/home/rer.png" alt="">
        </div>
        <div class="reg">
          <div class="registers">我要注册</div>
          <div class="lines"></div>
          <div class="retex">Online </div>
          <div class="retex">registration </div>
        </div>
      </div>
      <div class="archives" style="position: sticky;" @click="gotoArch">
        <div class="arTxt">信用档案</div>
        <div class="line"></div>
        <div class="uppTxt">archives</div>
        <div>
          <img style="position: absolute; bottom: 0; right: 0;" src="../../public/images/home/dajc.png" alt="">
        </div>
      </div>
      <div class="archives" style="position: sticky;" @click="gotoStudy">
        <div class="arTxt">在线学习</div>
        <div class="line"></div>
        <div class="uppTxt">learning</div>
        <div>
          <img style="position: absolute; bottom: 0; right: 0;" src="../../public/images/home/styjc.png" alt="">
        </div>
      </div>
      <div class="duan">
        <div class="kf" @click="goset">
          <div>
            <div class="kfTxt">在线客服</div>
            <div class="line"></div>
          </div>
          <div style="position: sticky;">
            <img style="position: absolute; bottom: 0; right: 0;" src="../../public/images/home/kf.png" alt="">
          </div>
        </div>
        <div class="kf" @click="gotofeed">
          <div>
            <div class="kfTxt">意见反馈</div>
            <div class="line"></div>
          </div>
          <div style="position: sticky;">
            <img style="position: absolute; bottom: 0; right: 0;" src="../../public/images/home/yjfk.png" alt="">
          </div>
        </div>
      </div>
    </div>
    <div class="titleShow">
      <titles></titles>
    </div>
    <div class="H5Show">
      <h5Tiles></h5Tiles>
    </div>
    <div v-show="bdShow"
      style="width: 25vw;height: 25vw;background: #fff; position: fixed;top: 200px; left:32.5vw ; z-index: 9999;border-radius: 15px; ">
      <div @click="guanbi" style="position: absolute; right: 0;cursor: pointer;">
        <img src="../../public/images/login/tuic.png" alt="" />
      </div>
      <div class="loginLr" style="margin-top: 5vw;">
        <Input v-model="form.phone" size="large" placeholder="请输入手机号"></Input>

        <div style="margin-top: 30px">
          <Input size="large" v-model="form.verificationCode" placeholder="请输入验证码">
          <Button type="error" slot="append" @click="Qcode">{{ Captcha == 0 ? "验证码" : Captcha }}</Button>
          </Input>
          <div class="ljlogin" style="cursor: pointer;" @click="bangd">立即绑定</div>
          <div class="yscr">
            <el-checkbox v-model="checked">我已阅读并同意</el-checkbox>
            <div style="line-height: 20px ;cursor: pointer;">
              <span @click="fuxy">《用户服务协议》</span>和
              <span @click="ysxy">《隐私政策》</span>
            </div>
          </div>
          <!-- <div class="line"></div> -->

        </div>
      </div>
    </div>
    <footers class="foots"></footers>
    <!-- <div class="zxkff" @click="gotoservice">
      <img src="../../public/images/home/zxkf.png" alt="">
    </div> -->
  </div>
</template>

<script>
import herder from '@/components/herder.vue'
import footers from '@/components/footer.vue'
import titles from '@/components/pc/titles.vue'
import h5Tiles from '@/components/h5/h5Tiles.vue'
import { iCar } from '@/api/home'
import { callback, sendSms, bangd } from "@/api/login";
import { getToken, setToken, setUserfo, setcodefo } from "@/util/auth";
import { zsID } from '@/api/archives'
import { search } from '@/api/search'
export default {
  inject: ['reload'],
  name: 'Home',
  components: {
    footers,
    herder,
    titles,
    h5Tiles,
  },
  data() {
    return {
      circleUrl: "https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png",
      iCarList: [],
      h5Login: true,
      bdShow: false,
      tisder: '请输入证书ID',
      activeKey: 1,
      activeIndex: "1",
      sidShow: false,
      butShow: true,
      herdShow: true,
      checked: false,
      input3: "",
      form: {
        verificationCode: '',
        phone: ''
      },
      Captcha: "验证码",
      select: "信用档案",
      wxcode: '',
      openid: '',
      list: [
        { name: "首页", path: '/' },
        { name: "平台介绍", path: '/about' },
        { name: "政策法规", path: '/policy' },
        { name: "行业资讯", path: '/info' },
        { name: "我要注册", path: '/enroll' },
        { name: "信用档案", path: '/archives' },
        { name: "在线学习", path: '/study' },
      ],
      loginShow: false
    };
  },
  created() {
    if (getToken() !== undefined) {
      this.h5Login = false
    }
    let url = window.location.href.split('#')[0];
    let search = url.split('?')[1];
    var a = search.split('&')
    var b = a[0].split('=')
    this.wxcode = b[1]


  },

  methods: {
    bangd() {
      var reg = /^1[3-9]\d{9}$/;
      var logo = reg.test(this.form.phone)
      if (this.checked == false) {
        this.$message.error("请勾用户服务选协议");
      } else if (logo == false) {
        this.$message.error("手机号格式错误！");
      } else if (this.form.verificationCode == '' || this.form.verificationCode == undefined || this.form.verificationCode == null) {
        this.$message.error("请输入验证码");
      } else {
        bangd({
          openid: this.openid,
          phone: this.form.phone,
          phoneCode: this.form.verificationCode
        }).then((res) => {
          if (res.result.token == '' || res.result.token == null) {
            this.$message.error("该手机号已被绑定");
          } else {
            this.bdShow = false
            setUserfo(JSON.stringify(res.result.user))
            setcodefo(res.result.QRCode)
            setToken(res.result.token)
            
            if(this.bdShow == false){
              this.$router.push({
              name: 'home',
            })
            this.$message({
              message: '绑定成功并登录！',
              type: 'success'
            });
            this.$router.go(0)
            location.reload()
            }
          }
        })
      }

    },
    guanbi() {
      this.bdShow = false
    },
    seacth(i, content) {

      if (content != '' && i == "资讯搜索" || i == 2 && content != '') {
        search({
          nieron: content,
          type: 0,
          pageSize: 1,
          currentPage: 10,
        }).then(res => {
          if (res.result.records == '') {
            this.input3 = ''
            this.$router.push({ name: "search", query: { cont: content } });
            this.$message.error('对不起，没有找到你想要的哦！');
          } else {
            this.input3 = ''
            this.$router.push({ name: "search", query: { cont: content } });
          }

        })

      } else if (i == '信用档案' && content != '') {
        zsID(
          {
            certificateId: content
          }
        ).then((res) => {
          if (res.result.certificateId == null || res.result.certificateId == '') {
            this.$message.error('对不起，没有找到你想要的哦！');
            this.input3 = ''
            this.$router.push({
              name: 'arDtails',
              query: {
                id: content,
              }
            })
          } else {
            this.input3 = ''
            this.$router.push({
              name: 'arDtails',
              query: {
                id: content,
              }
            })
          }
        })
      } else {
        return
      }
    },
    Qcode() {
      if (this.Captcha == '验证码') {
        this.Captcha = 60;
        this.sendSms()
        let time = setInterval(() => {
          if (this.Captcha === 0) {
            // clearInterval(time);
          } else {
            this.Captcha--;
          }
        }, 1000);
      } else {
        this.open2()
      }

    },
    sendSms() {
      sendSms(this.form.phone).then((res) => {
      })

    },
    open2() {
      this.$message.error('请稍后再试！');
    },
    selectchange(e) {
      if (e == 2) {
        this.tisder = '请输入关键字'
      } else {
        this.tisder = '请输入证书ID'
      }
    },
    ysxy() {
      this.loginShow = false;
      this.bjShow = false;
      this.wxLogShow = false;
      this.$router.push({
        name: 'agreement',
        query: {
          id: 6,
        }
      })
    },
    fuxy() {
      this.loginShow = false;
      this.bjShow = false;
      this.wxLogShow = false;
      this.$router.push({
        name: 'agreement',
        query: {
          id: 5,
        }
      })
    },
    _isMobile() {
      var os = function () {
        var ua = navigator.userAgent,
          isWindowsPhone = /(?:Windows Phone)/.test(ua),
          isSymbian = /(?:SymbianOS)/.test(ua) || isWindowsPhone,
          isAndroid = /(?:Android)/.test(ua),
          isFireFox = /(?:Firefox)/.test(ua),
          isChrome = /(?:Chrome|CriOS)/.test(ua),
          isTablet = /(?:iPad|PlayBook)/.test(ua) || (isAndroid && !/(?:Mobile)/.test(ua)) || (isFireFox && /(?:Tablet)/.test(ua)),
          isPhone = /(?:iPhone)/.test(ua) && !isTablet,
          isPc = !isPhone && !isAndroid && !isSymbian;
        return {
          isTablet: isTablet,
          isPhone: isPhone,
          isAndroid: isAndroid,
          isPc: isPc
        };
      }();

      if (os.isAndroid || os.isPhone) {
        console.log("手机")
        this.pt = "h5"
      } else if (os.isTablet) {
        this.pt = "h5"
        console.log("平板")
      } else if (os.isPc) {
        this.pt = "pc"
        console.log("电脑")
      }

    },
    baocun() {
      var a = []
      var b = []
      iCar().then((res) => {
        res.result.forEach((item, index) => {
          if (this.pt == 'h5') {
            a.push({
              advertisingCover: item.advertisingCover,
              link: item.h5Link,
              advertisingName: item.advertisingName,
            })
          } else {
            b.push({
              advertisingCover: item.advertisingCover,
              link: item.pcLink,
              advertisingName: item.advertisingName,
            })
          }
        })
        if (this.pt == 'h5') {
          this.iCarList = a
        } else {
          this.iCarList = b
        }

      })

    },
    gotoStudy() {
      this.$router.push('/study')
    },
    gotoArch() {
      this.$router.push('/archives')
    },
    gotoreg() {
      this.$router.push('/enroll')
    },
    gotoUser() {
      this.$router.push('/personal')
    },
    login() {
      this.$router.push('/login')
    },
    gotofeed() {
      this.$router.push('/feedback')
    },
    gotoservice() {
      this.$router.push('/service')
    },
    goset() {
      this.$router.push('/question')
    },
    fillBut() {
      this.sidShow = false
      this.butShow = true
    },
    sigButs() {
      this.sidShow = true
      this.butShow = false

    },
    onChange(item) {
      this.$router.push(item.path)
      if (item.path == '' || item.path == '/') {
        this.herdShow = true
      } else {
        this.herdShow = false
      }
    },
    handleSelect(key, keyPath) {
    },
    handleSelect(key, keyPath) {
    },
  },
  mounted() {
    if (this.wxcode !== '' && this.wxcode !== undefined && this.wxcode !== null) {
      var time = new Date().getTime()
      callback(this.wxcode, time).then(res => {
        if (res.result.token == '' || res.result.token == null) {
          this.bdShow = true
          this.openid = res.result.openid
        } else if (res.result.token !== '' && res.result.token !== null) {
          this.openid = res.result.openid
          setUserfo(JSON.stringify(res.result.user))
          setcodefo(res.result.QRCode)
          setToken(res.result.token)
          this.$message({
            message: '登录成功！',
            type: 'success'
          });
          this.$router.push({
            name: 'home',
          })
          this.$router.go(0)
          location.reload()
        }
      })
    }
    this._isMobile()
    this.baocun()
    window.scrollTo(0, 0);
  },
}
</script>
<style lang="less">
.loginLr {
  padding: 20px 40px;

}

.yscr {
  display: flex;
  margin-top: 30px;
  font-family: SourceHanSansCN-Regular;
  font-size: 14px;
  font-weight: normal;
  line-height: 14px;
  letter-spacing: 0em;
  color: #a0a0a0;

  span {
    color: #444444;
  }
}

.ljlogin {
  width: 100%;
  height: 48px;
  margin: auto;
  margin-top: 20px;
  background: #cc3636;
  border-radius: 5px;
  text-align: center;
  line-height: 48px;
  font-family: SourceHanSansCN-Regular;
  font-size: 16px;
  font-weight: normal;
  text-align: center;
  letter-spacing: 0em;
  color: #ffffff;
  opacity: 1;
}

.hInp {
  .el-select .el-input {
    width: 102px;
  }

  .el-input__inner {
    height: 30px !important;
    line-height: normal !important
  }

  .el-input__icon {
    line-height: normal !important;
  }
}

.el-carousel__indicator--horizontal {
  display: inline-block;
  padding: 12px 8px;
}
</style>
<style lang="less" scoped>
.van-sidebar {
  width: 180px;
}

.van-sidebar-item {
  padding: 20px 50px;
}

.res {
  border-radius: 5px;
}

.kf {
  border-radius: 5px;
}

.el-input__inner {
  border: 1px solid #cc3636 !important;
}

.el-select .el-input {
  width: 120px;
  background: rgba(204, 54, 54, 0.11);
  color: #cc3636;
}

.input-with-select .el-input-group__prepend {
  background-color: #fff;
}

.h5heder {
  display: flex;
  justify-content: space-between;
  background: #ffffff;
  padding: 20px 10px 10px 10px;
  z-index: 9999;

  .login {
    font-family: SourceHanSansCN-Regular;
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0em;
    line-height: 40px;
    color: #000000;
  }

  img {
    height: 15px;
    margin-top: 10px;
  }
}


@import "../assets/css/css.sass";
</style>
